import React, { useState, useEffect, useCallback, useMemo } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { fetchMerchants, checkForNewMerchants } from "./airtableService";
import debounce from "lodash.debounce";

const LoadingSkeleton = React.memo(() => (
  <motion.div
    style={{ padding: "12px 16px", borderBottom: "1px solid #e0e0e0" }}
    initial={{ opacity: 0, y: 10 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -10 }}
    transition={{ duration: 0.3 }}
  >
    <div
      style={{
        height: "20px",
        width: "80%",
        backgroundColor: "#f0f0f0",
        borderRadius: "4px",
      }}
    ></div>
  </motion.div>
));

const NotificationBar = React.memo(({ onRefresh }) => {
  const [hasNewData, setHasNewData] = useState(false);

  useEffect(() => {
    const checkInterval = setInterval(async () => {
      try {
        const result = await checkForNewMerchants();
        if (result.hasNewData) {
          setHasNewData(true);
        }
      } catch (error) {
        console.error("Error checking for new data:", error);
      }
    }, 10000);

    return () => clearInterval(checkInterval);
  }, []);

  const handleRefresh = () => {
    onRefresh();
    setHasNewData(false);
  };

  if (!hasNewData) return null;

  return (
    <motion.div
      initial={{ y: -50, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -50, opacity: 0 }}
      transition={{ duration: 0.3 }}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: "rgba(204, 16, 66, 0.9)",
        color: "white",
        padding: "15px",
        textAlign: "center",
        zIndex: 1000,
        boxShadow: "0 2px 10px rgba(0,0,0,0.2)",
        backdropFilter: "blur(5px)",
      }}
    >
      <p style={{ margin: "0 0 10px 0" }}>New merchant data is available!</p>
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={handleRefresh}
        style={{
          backgroundColor: "#ffffff",
          color: "#cc1042",
          border: "none",
          padding: "8px 16px",
          borderRadius: "20px",
          cursor: "pointer",
          fontWeight: "bold",
          fontSize: "14px",
        }}
      >
        Refresh Now
      </motion.button>
    </motion.div>
  );
});

const debouncedSearch = debounce((term, results, setFilteredResults) => {
  const filtered = results.filter((merchant) => {
    if (merchant && typeof merchant === "string") {
      return merchant.toLowerCase().includes(term.toLowerCase());
    }
    return false;
  });
  setFilteredResults(filtered);
}, 300);

const MerchantSearch = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isRefreshRequired, setIsRefreshRequired] = useState(false);
  const resultsPerPage = 5;

  const loadMerchants = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const merchants = await fetchMerchants();
      setResults(merchants);
      setFilteredResults(merchants);
      setIsRefreshRequired(false);
    } catch (error) {
      console.error("Error in loadMerchants:", error);
      setError("Failed to load merchants. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    loadMerchants();
  }, [loadMerchants]);

  useEffect(() => {
    debouncedSearch(searchTerm, results, setFilteredResults);
  }, [searchTerm, results]);

  const handleSearch = useCallback((term) => {
    setSearchTerm(term);
    setCurrentPage(1);
  }, []);

  const handleRefresh = useCallback(() => {
    loadMerchants();
  }, [loadMerchants]);

  const indexOfLastResult = currentPage * resultsPerPage;
  const indexOfFirstResult = indexOfLastResult - resultsPerPage;
  const currentResults = useMemo(
    () => filteredResults.slice(indexOfFirstResult, indexOfLastResult),
    [filteredResults, indexOfFirstResult, indexOfLastResult]
  );

  const handleRegister = useCallback(() => {
    const url = `https://mse2.aeonibrokersreward.com.my/mse-sales-challenge-campaign-merchant-onboarding/?company=${encodeURIComponent(
      searchTerm
    )}`;
    window.open(url, "_blank");
  }, [searchTerm]);
  return (
    <>
      <NotificationBar onRefresh={handleRefresh} />
      {isRefreshRequired && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: 999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
              textAlign: "center",
            }}
          >
            <p>New data is available. Please refresh to continue.</p>
            <button onClick={handleRefresh}>Refresh</button>
          </div>
        </div>
      )}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        style={{
          maxWidth: "600px",
          margin: "40px auto",
          padding: "32px",
          backgroundColor: "#ffffff",
          borderRadius: "16px",
          boxShadow: "0 10px 25px rgba(0, 0, 0, 0.1)",
          fontFamily: '"Calibri", Roboto, "Helvetica Neue", Arial, sans-serif',
        }}
      >
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "24px",
          }}
        >
          <img
            src="https://aeoninsurance.com.my/wp-content/uploads/2023/04/cropped-logo.png"
            alt="AEON Insurance Logo"
            style={{ height: "30px" }}
          />
        </motion.div>
        <motion.h2
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.3, duration: 0.5 }}
          style={{
            fontSize: "28px",
            fontWeight: "bold",
            textAlign: "center",
            marginBottom: "24px",
            color: "#983986",
          }}
        >
          Merchant Search Engine
        </motion.h2>
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.5 }}
          style={{ position: "relative", marginBottom: "24px" }}
        >
          <input
            type="text"
            placeholder="Search for a company..."
            value={searchTerm}
            onChange={(e) => handleSearch(e.target.value)}
            style={{
              width: "100%",
              padding: "14px 40px 14px 20px",
              fontSize: "16px",
              border: "2px solid #fcfafa",
              borderRadius: "30px",
              boxShadow: "0 4px 10px rgba(227, 227, 227)",
              outline: "none",
              boxSizing: "border-box",
              transition: "all 0.3s ease",
            }}
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#7b2cbf"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            style={{
              position: "absolute",
              right: "20px",
              top: "50%",
              transform: "translateY(-50%)",
              width: "20px",
              height: "20px",
            }}
          >
            <circle cx="11" cy="11" r="8"></circle>
            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
          </svg>
        </motion.div>

        <AnimatePresence mode="wait">
          {isLoading ? (
            <motion.div
              key="loading"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              {[...Array(5)].map((_, index) => (
                <LoadingSkeleton key={index} />
              ))}
            </motion.div>
          ) : error ? (
            <motion.div
              key="error"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              style={{ textAlign: "center", padding: "20px", color: "#d00000" }}
            >
              {error}
              <motion.button
                onClick={loadMerchants}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                style={{
                  marginTop: "16px",
                  padding: "10px 20px",
                  backgroundColor: "#7b2cbf",
                  color: "white",
                  border: "none",
                  borderRadius: "25px",
                  cursor: "pointer",
                  fontSize: "14px",
                  fontWeight: "bold",
                  transition: "all 0.3s ease",
                }}
              >
                Retry
              </motion.button>
            </motion.div>
          ) : (
            <motion.div
              key="results"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              {currentResults.length > 0 ? (
                <>
                  <ul
                    style={{
                      backgroundColor: "#ffffff",
                      borderRadius: "12px",
                      overflow: "hidden",
                      padding: 0,
                      listStyle: "none",
                      marginBottom: "24px",
                      border: "1px solid #ffffff",
                    }}
                  >
                    {currentResults.map((merchant, index) => (
                      <motion.li
                        key={index}
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ delay: index * 0.1 }}
                        whileHover={{
                          backgroundColor: "#f3e8ff",
                          paddingLeft: "24px",
                          transition: { duration: 0.2 },
                        }}
                        style={{
                          padding: "14px 20px",
                          borderBottom:
                            index !== currentResults.length - 1
                              ? "1px solid #e0aaff"
                              : "none",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          cursor: "pointer",
                          transition: "all 0.3s ease",
                        }}
                      >
                        <span style={{ color: "#4a0e78" }}>
                          {merchant || "Unknown Merchant"}
                        </span>
                        <motion.svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#7b2cbf"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          initial={{ x: 0, opacity: 0.5 }}
                          whileHover={{ x: 5, opacity: 1 }}
                          transition={{ duration: 0.2 }}
                        >
                          <polyline points="9 18 15 12 9 6"></polyline>
                        </motion.svg>
                      </motion.li>
                    ))}
                  </ul>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <motion.button
                      whileHover={{ scale: 1.05, backgroundColor: "#080808" }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() =>
                        setCurrentPage((prev) => Math.max(prev - 1, 1))
                      }
                      disabled={currentPage === 1}
                      style={{
                        padding: "10px 20px",
                        backgroundColor:
                          currentPage === 1 ? "#080808" : "#080808",
                        color: "white",
                        border: "none",
                        borderRadius: "25px",
                        cursor: currentPage === 1 ? "not-allowed" : "pointer",
                        fontSize: "14px",
                        fontWeight: "bold",
                        transition: "all 0.3s ease",
                      }}
                    >
                      ← Previous
                    </motion.button>
                    <span style={{ color: "#080808", fontSize: "14px" }}>
                      Page {currentPage} of{" "}
                      {Math.ceil(filteredResults.length / resultsPerPage)}
                    </span>
                    <motion.button
                      whileHover={{ scale: 1.05, backgroundColor: "#cc1042" }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() =>
                        setCurrentPage((prev) =>
                          Math.min(
                            prev + 1,
                            Math.ceil(filteredResults.length / resultsPerPage)
                          )
                        )
                      }
                      disabled={indexOfLastResult >= filteredResults.length}
                      style={{
                        padding: "10px 20px",
                        backgroundColor:
                          indexOfLastResult >= filteredResults.length
                            ? "#cc1042"
                            : "#cc1042",
                        color: "white",
                        border: "none",
                        borderRadius: "25px",
                        cursor:
                          indexOfLastResult >= filteredResults.length
                            ? "not-allowed"
                            : "pointer",
                        fontSize: "14px",
                        fontWeight: "bold",
                        transition: "all 0.3s ease",
                      }}
                    >
                      Next →
                    </motion.button>
                  </div>
                </>
              ) : (
                <motion.div
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.9 }}
                  transition={{ duration: 0.3 }}
                  style={{ textAlign: "center", padding: "24px 0" }}
                >
                  <p
                    style={{
                      marginBottom: "16px",
                      fontSize: "16px",
                      color: "#cc1042",
                    }}
                  >
                    No results found for "{searchTerm}".
                  </p>
                  <motion.button
                    whileHover={{ scale: 1.05, backgroundColor: "#cc1042" }}
                    whileTap={{ scale: 0.95 }}
                    onClick={handleRegister}
                    style={{
                      backgroundColor: "#cc0142",
                      color: "white",
                      padding: "14px 28px",
                      fontSize: "16px",
                      fontWeight: "bold",
                      border: "none",
                      borderRadius: "30px",
                      cursor: "pointer",
                      transition: "all 0.3s ease",
                      boxShadow: "0 4px 10px rgba(123, 44, 191, 0.3)",
                    }}
                  >
                    Register New Company
                  </motion.button>
                </motion.div>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </>
  );
};

export default MerchantSearch;
