import Airtable from "airtable";

Airtable.configure({
  apiKey:
    "patJxl34QyRxO5ig8.a72289b45eb16a91367c7b677829e87dea9f5da26ab1c33809b1b685eff763e5",
});

const base = Airtable.base("appL5ubsE49b6dzdM");

let lastFetchedRecords = [];

export const fetchMerchants = async () => {
  try {
    const records = await base("tblJ1z8tfc6OzU8UT")
      .select({
        fields: ["Merchant Name"],
        sort: [{ field: "Merchant Name", direction: "asc" }],
      })
      .all();

    lastFetchedRecords = records.map((record) => record.getId());
    return records
      .map((record) => record.get("Merchant Name"))
      .filter((name) => name);
  } catch (error) {
    console.error("Error fetching merchants:", error);
    return [];
  }
};

export const checkForNewMerchants = async () => {
  try {
    const records = await base("tblJ1z8tfc6OzU8UT")
      .select({
        fields: ["Merchant Name"],
      })
      .all();

    const newRecordIds = records.map((record) => record.getId());
    const hasChanged =
      newRecordIds.length !== lastFetchedRecords.length ||
      newRecordIds.some((id) => !lastFetchedRecords.includes(id)) ||
      lastFetchedRecords.some((id) => !newRecordIds.includes(id));

    if (hasChanged) {
      lastFetchedRecords = newRecordIds;
      return {
        hasNewData: true,
        newCount: newRecordIds.length,
        oldCount: lastFetchedRecords.length,
      };
    }
    return {
      hasNewData: false,
      newCount: newRecordIds.length,
      oldCount: lastFetchedRecords.length,
    };
  } catch (error) {
    console.error("Error checking for new merchants:", error);
    return { hasNewData: false, error };
  }
};

export const registerNewMerchant = async (merchantName) => {
  try {
    await base("tblJ1z8tfc6OzU8UT").create({
      "Merchant Name": merchantName,
    });
    return true;
  } catch (error) {
    console.error("Error registering new merchant:", error);
    return false;
  }
};
